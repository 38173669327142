import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import * as Sentry from '@sentry/browser';
import format from 'string-template';
import moment from 'moment';
import { autorun } from 'mobx';

import { formatAmoutInCents } from '../../../../utils/formatters';
import UserContract from '../../../../Model/UserContract';
import ExternalCoach from '../../../../Model/ExternalCoach';
import Coach from '../../../../Model/Coach';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import useSessionStore from '../../../../hooks/useSessionStore';
import { isDev, isTestRun } from '../../../../utils/environment';
import {
  ContractMinimumValue,
  ContractProperties,
  ContractStatus,
  DefaultContract,
  getContractDocContent,
  FeeConfig,
  ContractMaxValue,
  ContractType,
} from '../../../utils/userContract';
import useToast from '../../../hooks/useToast';
import { generateTerms } from '../../../utils/userContractTerms';
import { ContractState } from '../utils/ContractTypes';
import ContractModalContext, { initialValues } from './ContractModalContext';
import texts from './texts.json';

// This constant is used when checking if a term corresponds to a single month.
const ONE_MONTH = 1;

// This is the IS Id that we use when we reonboard a client after the first month.
const REONBOARDING_INSIDE_SALES_ID = isDev ? 'HpVorB48wOP8OeugZHcRfYt88hW2' : 'sMZWMRtSitU2Rqii9n9tiJHlrJm2';

const ContractModalContextProvider = ({
  children,
}) => {
  const [coachId, setCoachId] = useState(initialValues.coachId);
  const [isLoadingData, setIsLoadingData] = useState(initialValues.isLoadingData);
  const [isLoadingContract, setIsLoadingContract] = useState(initialValues.isLoadingContract);
  const isLoading = isLoadingData || isLoadingContract;
  const [coachDoc, setCoachDoc] = useState(initialValues.coachDoc);
  const [externalCoachDoc, setExternalCoachDoc] = useState(initialValues.externalCoachDoc);
  const [products, setProducts] = useState(initialValues.products);
  const [previousContract, setPreviousContract] = useState(initialValues.previousContract);
  const [contract, setContract] = useState(initialValues.contract);
  const [contractDoc, setContractDoc] = useState(initialValues.contractDoc);
  const [contractState, setContractState] = useState(ContractState.CONFIGURATION);
  const [isSaving, setIsSaving] = useState(initialValues.isSaving);
  const [lead, setLead] = useState(initialValues.lead);
  const [user, setUser] = useState(initialValues.user);
  const [isReonboardingContract, setIsReonboardingContract] = useState(initialValues.isReonboardingContract);
  const [latestContract, setLatestContract] = useState(initialValues.latestContract);
  const [contractType, setContractType] = useState(initialValues.contractType);

  // If there is only one product, select it by default. Otherwise, let the IS choose the initial product.
  const [selectedProduct, setSelectedProduct] = useState(products.length === 1 ? products[0] : null);
  const {
    userDoc: sellerDoc,
  } = useContext(LoggedInUserContext);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const {
    isInsideSales: isLoggedInUserInsideSales,
    isCoachAssistant: isLoggedInUserCoachAssistant,
  } = useSessionStore();

  useEffect(() => {
    // Load the coach data here since this modal can now be used with leads coming from different sources.
    const loadCoachData = async () => {
      setIsLoadingData(true);
      const coachDocument = await Coach.getCoachById(coachId);
      const externalCoach = new ExternalCoach(coachId);

      await Promise.all([
        coachDocument.init(),
        externalCoach.init(),
      ]);

      const externalCoachProducts = await externalCoach.getProducts();

      if (isComponentMountedRef.current) {
        setCoachDoc(coachDocument);
        setExternalCoachDoc(externalCoach);
        setProducts(externalCoachProducts);
        setContract((currentContract) => ({
          ...currentContract,
          coachConditions: coachDocument.termsAndConditionsText,
          currency: externalCoach.defaultCurrency || DefaultContract.currency,
        }));
        setIsLoadingData(false);
      }
    };

    if (coachId) {
      loadCoachData();
    }
  }, [
    coachId,
    isComponentMountedRef,
  ]);

  /*
    Check whether the user is reonboarding after the first month. This is a special case, and we assign different
    values to the sellerId and the IS fee.
  */
  const isWithinFirstMonth = useMemo(() => (
    user && moment().isBefore(moment(user.serviceStartAt).add(ONE_MONTH, 'month'))
  ), [user]);

  // Check if an AC is creating a reonboarding contract.
  const isReonboardingByAC = isReonboardingContract && isLoggedInUserCoachAssistant;

  const getContractFees = useCallback((product = {}) => {
    // Get the configured values from the coach document. These are the default values.
    const {
      feesConfig: {
        baseFee: coachBaseFee,
        assistantCoachFee: coachAssistantCoachFee,
        insideSalesFee: coachInsideSalesFee,
      },
      isEnrolledInISProgram,
      isACEnabled,
    } = coachDoc;

    // If there is no coachBaseFee, get baseFee from passed product as param.
    // This allows getting fees and updating the product in the same event loop cycle.
    // Otherwise - get it from selectedProduct state.
    const baseFee = coachBaseFee?.value || product?.baseFee || selectedProduct?.baseFee;

    // All coaches must have a base fee configured. Report to sentry when it is missing
    if (typeof baseFee === 'undefined') {
      Sentry.captureMessage('Coach is missing base fee configuration', {
        extra: {
          coach: coachDoc.id,
        },
      });
    }

    const isFirstMonthReonboardingWithISFee = isReonboardingByAC
      && isWithinFirstMonth
      && !!latestContract?.fees?.insideSalesFee?.value;

    /*
      There are three cases where we should add the IS fee to the contract state:
        1. The coach is enrolled in the IS program and the logged in user is an IS agent.
        2. The logged in user is an AC creating a reonboarding contract after the first month.
        3. An AC is reonboarding during their first month and the original contract had an IS fee.
    */
    const shouldAddISFee = (isEnrolledInISProgram && isLoggedInUserInsideSales)
      || (isReonboardingByAC && !isWithinFirstMonth && isEnrolledInISProgram)
      || isFirstMonthReonboardingWithISFee;

    // Calculate the IS fee value based on the configuration and type of contract created.
    const ISFeeValue = isFirstMonthReonboardingWithISFee
      ? latestContract?.fees?.insideSalesFee?.value
      : coachInsideSalesFee?.value || FeeConfig.INSIDE_SALES.value;

    /*
      Set coach fees initial values. If AC or IS are not enabled for this coach, we won't add
      AC or IS fees to the contract state.
    */
    const contractFeesValues = {
      [ContractProperties.BASE_FEE]: baseFee,
      ...(isACEnabled
        ? { [ContractProperties.AC_FEE]: coachAssistantCoachFee?.value || FeeConfig.ASSISTANT_COACH.value }
        : {}),
      ...(shouldAddISFee
        ? { [ContractProperties.IS_FEE]: ISFeeValue }
        : {}),
    };

    return contractFeesValues;
  }, [
    coachDoc,
    selectedProduct,
    isLoggedInUserInsideSales,
    isWithinFirstMonth,
    latestContract,
    isReonboardingByAC,
  ]);

  const resetContract = useCallback(() => {
    const contractFees = getContractFees();
    // Keep contract name and seller values on reset
    setContract(({
      [ContractProperties.NAME]: currentName,
      [ContractProperties.SELLER_ID]: currentSellerId,
    }) => ({
      ...DefaultContract,
      ...contractFees,
      coachConditions: coachDoc.termsAndConditionsText,
      currency: externalCoachDoc?.defaultCurrency || DefaultContract.currency,
      [ContractProperties.NAME]: currentName || DefaultContract[ContractProperties.NAME],
      [ContractProperties.SELLER_ID]: currentSellerId,
    }));
  }, [
    coachDoc,
    externalCoachDoc,
    getContractFees,
  ]);

  const loadContractByLead = useCallback(async (leadObj) => {
    if (leadObj.id) {
      setIsLoadingContract(true);
      const existingContract = await UserContract.getContractByLeadId(leadObj.id);

      if (isComponentMountedRef.current) {
        if (existingContract) {
          setContractDoc(existingContract);
          setContractType(existingContract.type);
        } else {
          setIsLoadingContract(false);
        }

        setLead(leadObj);
      }
    } else {
      showToast(texts.leadIdError, { error: true });
    }
  }, [
    isComponentMountedRef,
    showToast,
  ]);

  const loadReonboardingContractByUser = useCallback(async (userObj) => {
    if (userObj.id) {
      setIsLoadingContract(true);
      const existingUserReonboardingContracts = await UserContract
        .getReonboardingContractsCollectionForUser(userObj.id);

      /*
        If this is the first time we are reonboarding this user, we have to get the latest contract to extract some
        info we'll need after.
      */
      if (!existingUserReonboardingContracts.size) {
        /*
          Get the list of contracts that were previously created for this user. We may have to extract the fees
          configuration that was set on the latest one.
        */
        const userContracts = await UserContract.getContractsByUserId(userObj.id);
        const [latestContractDoc] = userContracts.docs.slice()
          .filter(({ status }) => status !== ContractStatus.PENDING && status !== ContractStatus.IN_PROGRESS)
          .sort((a, b) => b.createdAt - a.createdAt);

        if (latestContractDoc && isComponentMountedRef.current) {
          setLatestContract(latestContractDoc);
        }
      }

      autorun(() => {
        if (isComponentMountedRef.current) {
          if (existingUserReonboardingContracts.hasDocs) {
            const existingUserReonboardingContract = existingUserReonboardingContracts.docs[0];
            setContractDoc(existingUserReonboardingContract);
            setContractType(existingUserReonboardingContract.type);
          } else {
            setIsLoadingContract(false);
          }
          setIsReonboardingContract(true);
          setUser(userObj);
        }
      });
    } else {
      showToast(texts.userIdError, { error: true });
    }
  }, [
    isComponentMountedRef,
    showToast,
  ]);

  const loadContractFromDoc = useCallback((contractDocument) => {
    const {
      startDate: stringDate,
      initialPaymentInCents,
      totalPriceInCents,
      upfrontPaymentInCents,
      initialTerm,
      minSubscriptionMonths,
      currency,
      productId,
      priceId,
      monthlyPriceId,
      packageId,
      packageName,
      cancelAtPeriodEnd,
      recurringBillingMonths,
      coachConditions,
      version,
      type,
      createdAt,
      updatedAt,
      sellerId,
      name = DefaultContract[ContractProperties.NAME],
      fees: {
        baseFee: { value: baseFee } = {},
        assistantCoachFee: { value: assistantCoachFee } = {},
        insideSalesFee: { value: insideSalesFee } = {},
      } = {},
    } = contractDocument;

    // If baseFee is missing we should report it to sentry
    if (typeof baseFee === 'undefined') {
      Sentry.captureMessage('Contract is missing base fee', {
        extra: {
          coach: contractDoc.id,
        },
      });
    }

    /*
      If there is an existing contract, we need to set the contract state to DETAILS, so that we can display
      the contract terms.
    */
    setContractState(ContractState.DETAILS);

    const filteredProduct = products.filter((product) => (product.id === productId));

    setSelectedProduct(filteredProduct.length ? filteredProduct[0] : null);

    /*
      Calculate the upfront amount based on the initial payment and the upfront payment. This will be set to 0 if the
      startDate is not in the future.
    */
    let upfrontPayment = 0;
    if (moment(stringDate).isAfter(moment().startOf('day'))) {
      upfrontPayment = formatAmoutInCents(upfrontPaymentInCents, { minimumFractionDigits: 2 });
    }

    setContract({
      startDate: moment(stringDate),
      initialPayment: formatAmoutInCents(initialPaymentInCents, { minimumFractionDigits: 2 }),
      monthlyPayment: formatAmoutInCents(totalPriceInCents, { minimumFractionDigits: 2 }),
      upfrontPayment,
      initialTerm,
      minSubscriptionMonths,
      currency,
      priceId,
      monthlyPriceId,
      packageId,
      packageName,
      cancelAtPeriodEnd,
      recurringBillingMonths,
      coachConditions,
      version,
      createdAt,
      updatedAt,
      baseFee,
      sellerId,
      name,
      ...(insideSalesFee ? { insideSalesFee } : {}),
      ...(assistantCoachFee ? { assistantCoachFee } : {}),
    });
    setContractType(type);
    setPreviousContract(null);

    setIsLoadingContract(false);
  }, [
    contractDoc,
    products,
  ]);

  const loadReusableContractFromDoc = useCallback((selectedContractDoc) => {
    if (selectedContractDoc.id) {
      setIsLoadingContract(true);
      if (isComponentMountedRef.current) {
        loadContractFromDoc(selectedContractDoc);
        setContractDoc(selectedContractDoc);
        setContractType(selectedContractDoc.type);
        setIsLoadingContract(false);
      }
    } else {
      showToast(texts.contractIdError, { error: true });
    }
  }, [
    isComponentMountedRef,
    loadContractFromDoc,
    showToast,
  ]);

  useEffect(() => {
    if (products && products.length && contractDoc) {
      loadContractFromDoc(contractDoc);
    }
  }, [
    products,
    contractDoc,
    loadContractFromDoc,
    isComponentMountedRef,
  ]);

  const isValidContract = useMemo(() => {
    const {
      startDate,
      initialPayment,
      initialTerm,
      minSubscriptionMonths,
      monthlyPayment,
      recurringBillingMonths,
      sellerId,
      baseFee,
      assistantCoachFee = 0,
      insideSalesFee = 0,
      upfrontPayment = 0,
    } = contract;

    const isFeeValid = (fee) => fee <= ContractMaxValue.FEE_PERCENTAGE && fee >= ContractMinimumValue.FEE_PERCENTAGE;
    const feesSum = (baseFee || 0) + assistantCoachFee + insideSalesFee;

    const isDateValid = startDate?.isValid() && startDate.isAfter(moment().startOf('day').subtract(1, 'day'));

    // We just validate if a seller is sellected when the contract is a BASE contract
    const isSellerValid = contractType !== ContractType.BASE || !!sellerId;

    const isValid = !!selectedProduct
      && isDateValid
      && minSubscriptionMonths >= ContractMinimumValue.MIN_SUBSCRIPTION_MONTHS
      && minSubscriptionMonths >= recurringBillingMonths
      && (minSubscriptionMonths % recurringBillingMonths === 0)
      && (minSubscriptionMonths >= initialTerm)
      // allow 0 monthly payment for test runs
      && (Number(monthlyPayment) > 0 || isTestRun)
      && initialTerm > 0
      // allow 0 initial payment for test runs
      && (Number(initialPayment) > 0 || isTestRun)
      && isSellerValid
      && typeof baseFee !== 'undefined'
      && isFeeValid(baseFee)
      && isFeeValid(assistantCoachFee)
      && isFeeValid(insideSalesFee)
      && isFeeValid(feesSum)
      && upfrontPayment <= initialPayment;
    return isValid;
  }, [
    contract,
    contractType,
    selectedProduct,
  ]);

  const onContractFieldChange = useCallback((name, value) => {
    let newContractValues = {};

    switch (name) {
      case ContractProperties.INITIAL_PAYMENT:
        // We save the initial payment.
        newContractValues[ContractProperties.INITIAL_PAYMENT] = value;

        /*
          If the initial term is 1, then we are configuring a monthly contract, so we set the same value to the
          monthly payment. The monthly payment can then be modified independently.
        */
        if (contract[ContractProperties.INITIAL_TERM] === ONE_MONTH) {
          newContractValues[ContractProperties.MONTHLY_PAYMENT] = value;
        }
        break;
      case ContractProperties.INITIAL_TERM:
        newContractValues[ContractProperties.INITIAL_TERM] = Number(value);
        /*
          When the initial term changes to 1, we have to configure the monthly payment to be the same as the initial
          term payment. We take the current value of the contract and assign it to the monthly payment.
        */
        if (newContractValues[ContractProperties.INITIAL_TERM] === ONE_MONTH) {
          newContractValues[ContractProperties.MONTHLY_PAYMENT] = contract[ContractProperties.INITIAL_PAYMENT];
        }
        break;
      case ContractProperties.START_DATE:
        // Save the start date without any modifications in the contract object.
        newContractValues = {
          [name]: value,
        };

        /*
          If the new startDate is not a future startDate, then we set the upfront payment to 0, since the client will
          have to pay the full amount the moment they subscribe.
        */
        if (!value.isAfter(moment().startOf('day'))) {
          newContractValues[ContractProperties.UPFRONT_PAYMENT] = 0;
        }
        break;
      case ContractProperties.UPFRONT_PAYMENT:
      case ContractProperties.MONTHLY_PAYMENT:
      case ContractProperties.MIN_SUBSCRIPTION_MONTHS:
      case ContractProperties.BASE_FEE:
      case ContractProperties.IS_FEE:
      case ContractProperties.AC_FEE:
        newContractValues = {
          [name]: Number(value),
        };
        break;
      default:
        newContractValues = {
          [name]: value,
        };
        break;
    }

    setContract((currentContract) => ({
      ...currentContract,
      ...newContractValues,
    }));
  }, [
    contract,
  ]);

  const onPackageSelected = useCallback((packageItem) => {
    const {
      initialPaymentInCents: initialPayment,
      initialTerm,
      minSubscriptionMonths,
      monthlyPaymentInCents: monthlyPayment,
      priceId,
      monthlyPriceId,
      currency,
      id: packageId,
      name: packageName,
    } = packageItem;

    if (typeof initialPayment !== 'number' || typeof monthlyPayment !== 'number') {
      const errorMessage = format(texts.packageError, { packageName: packageItem.name });
      showToast(errorMessage, { error: true });

      Sentry.captureException(new Error(errorMessage), {
        extra: {
          package: JSON.stringify(packageItem, null, 2),
          coachId: coachDoc.id,
        },
      });
      return;
    }

    const contractFees = getContractFees();
    setContract(({
      [ContractProperties.SELLER_ID]: currentSellerId,
      [ContractProperties.NAME]: currentName,
    }) => ({
      ...DefaultContract,
      ...contractFees,
      [ContractProperties.INITIAL_PAYMENT]: formatAmoutInCents(initialPayment, { minimumFractionDigits: 2 }),
      priceId,
      initialTerm,
      minSubscriptionMonthsPlaceholder: minSubscriptionMonths,
      [ContractProperties.MONTHLY_PAYMENT]: formatAmoutInCents(monthlyPayment, { minimumFractionDigits: 2 }),
      monthlyPriceId,
      currency,
      coachConditions: coachDoc.termsAndConditionsText,
      packageId,
      packageName,
      [ContractProperties.NAME]: currentName || DefaultContract[ContractProperties.NAME],
      ...(currentSellerId ? { [ContractProperties.SELLER_ID]: currentSellerId } : {}),
    }));
  }, [
    coachDoc,
    showToast,
    getContractFees,
  ]);

  const onProductChange = useCallback((product) => {
    setSelectedProduct(product);
    const contractFees = getContractFees(product);
    setContract(({
      [ContractProperties.SELLER_ID]: currentSellerId,
      [ContractProperties.NAME]: currentName,
    }) => {
      const result = {
        ...DefaultContract,
        ...contractFees,
        [ContractProperties.PACKAGE_ID]: '',
        [ContractProperties.PACKAGE_NAME]: '',
        [ContractProperties.NAME]: currentName || DefaultContract[ContractProperties.NAME],
        ...(currentSellerId ? { [ContractProperties.SELLER_ID]: currentSellerId } : {}),
      };

      return result;
    });
  }, [
    setSelectedProduct,
    getContractFees,
  ]);

  /**
   * Generates a contract based on the existing context data.
   * if the contract is already saved, it will update the existing contract with updated data.
   * If the contract is not saved, it will create a new one.
   * @returns {Object} An object indicating the success of the operation.
   */
  const generateContract = useCallback(async () => {
    // This will hold the new contract content, if we need to create one.
    let newContract;

    setIsSaving(true);

    const {
      isEnrolledInISProgram,
    } = coachDoc;

    /*
      First, we need to calculate the seller ID based on different conditions. If this is a reonboarding contract, we
      have to check whether it is being created within the first month of the subscription, or after.
      In the case of IS agents and coaches, their ids are always saved on the contract.
      For ACs, though, we have to check for two conditions:
        1. If the contract is created within the first month, we use the values extracted from that contract.
        2. If the contract is created after the first month, we use the REONBOARDING_INSIDE_SALES_ID.
    */
    let sellerId = sellerDoc.id; // Default to the logged in user, which covers the case of coaches creating contracts.

    if (isEnrolledInISProgram && isLoggedInUserInsideSales) {
      sellerId = sellerDoc.id;
    } else if (isReonboardingByAC) {
      if (isWithinFirstMonth) {
        sellerId = latestContract?.sellerId;
      } else {
        sellerId = REONBOARDING_INSIDE_SALES_ID;
      }
    }

    const contractDocContent = getContractDocContent({
      lead,
      user,
      contract,
      product: selectedProduct,
      coachDoc,
      sellerId,
    });

    try {
      // If there's a contract document loaded, then this is an update.
      if (contractDoc) {
        /*
        Verify that the lead ID is the same as the one in the contract.
        Or if it is a reusable contract which doesn't need a leadId.
        If it's a reonboarding contract, verify that the user ID is the
        same as the one in the contract.
        */
        if ((contractType === ContractType.BASE || contractDoc.leadId === lead.id)
          || (isReonboardingContract && contractDoc.userId === user.id)) {
          await contractDoc.update({
            ...contractDocContent,
            updatedAt: new Date(),
            [ContractProperties.TYPE]: contractType,
          });
        } else {
          const errorMessage = isReonboardingContract ? texts.contractUserIdError : texts.contractLeadIdError;
          Sentry.captureException(
            new Error(errorMessage),
            {
              extra: {
                contract: contractDoc.id,
                contractLeadId: contractDoc.leadId,
                contractUserId: contractDoc.userId,
                leadId: lead.id,
                userId: user.id,
              },
            },
          );
          showToast(errorMessage, { error: true });
          setIsSaving(false);
          return { isSuccessful: false };
        }
      } else {
        newContract = await UserContract.addDoc({
          ...contractDocContent,
          createdAt: new Date(),
          [ContractProperties.TYPE]: contractType,
        });
        // If there is a lead, we update the lead with the contract creation date.
        if (lead?.exists) {
          await lead.updateFields({
            contractCreatedAt: moment().toDate(),
          });
        }
      }

      if (isComponentMountedRef.current) {
        // If a new contract has been generated, then we use it from now on.
        if (newContract) {
          setContractDoc(newContract);
        }
        setIsSaving(false);
        loadContractFromDoc(newContract || contractDoc);
      }
      return { isSuccessful: true };
    } catch (error) {
      if (isComponentMountedRef.current) {
        showToast(texts.contractError, { error: true });
        Sentry.captureException(error, {
          extra: {
            contract: JSON.stringify(contractDocContent, null, 2),
          },
        });
        setIsSaving(false);
      }
    }
    return { isSuccessful: false };
  }, [
    coachDoc,
    contract,
    contractDoc,
    selectedProduct,
    sellerDoc,
    isComponentMountedRef,
    showToast,
    lead,
    contractType,
    user,
    isReonboardingContract,
    loadContractFromDoc,
    latestContract.sellerId,
    isWithinFirstMonth,
    isLoggedInUserInsideSales,
    isReonboardingByAC,
  ]);

  const editContract = useCallback(() => {
    // Save the previous contract state with the current values to be able to revert changes if needed.
    if (!isReonboardingContract) { // TODO: We have to decouple this from the context.
      setPreviousContract({ ...contract });
    }

    setContractState(ContractState.CONFIGURATION);
  }, [
    contract,
    isReonboardingContract,
  ]);

  const viewTerms = useCallback(() => {
    /*
      If we are viewing the terms, then it means we have not saved any changes, so we can reset the contract to the
      previous state.
    */
    if (!isReonboardingContract && previousContract) {
      setContract({ ...previousContract });
    }

    setPreviousContract(null);
    setContractState(ContractState.DETAILS);
  }, [
    previousContract,
    isReonboardingContract,
  ]);

  /*
    Generate the terms when the contract changes. We have to use a memo to avoid generating the terms on every
    render. Even though this is not a heavy operation, the context would change on every render, and that would
    cause all children to re-render unnecessary.
  */
  const contractTerms = useMemo(() => generateTerms(contract), [contract]);

  /*
    If the contract exists and is not in the PENDING status, then it means it was already used.
    We should not make changes to this contract.
  */
  const isReadOnly = !!contractDoc && (
    contractDoc.status !== ContractStatus.PENDING
    || (contractDoc.type === ContractType.BASE && contractDoc.usageCount > 0)
  );

  const context = useMemo(() => ({
    coachDoc,
    externalCoachDoc,
    products,
    contract,
    contractDoc,
    contractState,
    selectedProduct,
    isLoading,
    isLoadingContract,
    isSaving,
    isValidContract,
    isReadOnly,
    contractTerms,
    contractType,
    lead,
    user,
    isReonboardingContract,
    coachId,
    setCoachId,
    loadContractByLead,
    loadReonboardingContractByUser,
    loadReusableContractFromDoc,
    onContractFieldChange,
    onPackageSelected,
    onProductChange,
    resetContract,
    generateContract,
    editContract,
    viewTerms,
    setContractState,
    setContractType,
  }), [
    coachDoc,
    externalCoachDoc,
    products,
    contract,
    contractDoc,
    contractState,
    selectedProduct,
    isLoading,
    isLoadingContract,
    isSaving,
    isValidContract,
    isReadOnly,
    contractTerms,
    contractType,
    lead,
    user,
    isReonboardingContract,
    coachId,
    setCoachId,
    loadContractByLead,
    loadReonboardingContractByUser,
    loadReusableContractFromDoc,
    onContractFieldChange,
    onPackageSelected,
    onProductChange,
    resetContract,
    generateContract,
    editContract,
    viewTerms,
    setContractState,
    setContractType,
  ]);

  return (
    <ContractModalContext.Provider value={context}>
      {children}
    </ContractModalContext.Provider>
  );
};

ContractModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  observer,
)(ContractModalContextProvider);
